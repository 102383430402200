<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>基础设置</el-breadcrumb-item>
            <el-breadcrumb-item>级别维护</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
<!--                <el-button type="primary" size="small" @click="addDialog = true">添加级别</el-button>-->
            </div>
            <!--------------------用户表格--------------------->
            <!--            级别、价格-->
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="级别名称"></el-table-column>
                <el-table-column prop="" label="操作" >
                    <template slot-scope="scope" >
                        <span>-</span>
<!--                        <el-button type="text" size="small" @click="editItem(scope.row)">编辑</el-button>-->
<!--                        <el-divider direction="vertical"></el-divider>-->
<!--                        <el-button type="text" size="small" @click="removeItem(scope.row)">删除</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!--添加对话框-->
            <el-dialog :title="isEdit ? '编辑级别' : '添加级别'" :visible.sync="addDialog" width="592px"  append-to-body  @close="closeDialog">
                <div class="dialog-content">
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
                        <el-form-item label="级别名称：" prop="name">
                            <el-input size="small" v-model="form.name" placeholder="请输入" ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addDialog = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">保 存</el-button>
            </span>
            </el-dialog>

        </el-card>
    </div>
</template>

<script>
export default {
    name: "list",

    data() {
        return {
            list: [],//表格列表
            loading: false,//表格加载loading
            addDialog: false,//添加弹窗
            form: {//表单提交内容
                name: '',
            },
            rules: {// 表单校验规则对象
                name: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            btnLoading: false,//按钮状态
            isEdit: false,//是否编辑
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
        }
    },
    mounted () {
        this.getList()//查列表
    },
    methods: {
        //查列表
        async getList() {
            this.loading = true;
            let data = {
                type: 'level',
                pageType: 1,//0不分页 1分页
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/get/dict', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.number = newSize
            this.query.page = 1
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
        //添加/编辑
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let param = {
                        name: this.form.name,
                        type: 'level',
                    }
                    let res = {};
                    if (this.isEdit) {
                        param.id = this.form.id;
                        res = await this.$http.post('/admin/api/update/dict', param)
                    } else {
                        res = await this.$http.post('/admin/api/add/dict', param)
                    }
                    console.log('res:', res)
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        //this.$message({message: '操作成功！', type: 'success'});
                        this.closeDialog();
                        await this.getList();
                    } else {
                        this.btnLoading = false;
                        //this.$message({message: res.data.message, type: 'error'});
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeDialog() {
            if (this.form.id) {
                delete this.form.id;
            }
            this.isEdit = false;
            this.btnLoading = false;
            this.$refs['ruleForm'].resetFields();
            this.addDialog = false;
        },
        //编辑
        async editItem(rows) {
            this.isEdit = true;
            this.$nextTick(() => {
                this.form.id = rows.id;
                this.form.name = rows.name;
            })
            this.addDialog = true; //打开对话框
        },
        //删除
        removeItem(rows) {
            this.$confirm('此操作将永久删除', '确认要删除“' + rows.name + '”吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let res = await this.$http.post('/admin/api/delete/dict', {id: rows.id})
                        console.log('res:', res)
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.getList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
    }
}
</script>

<style scoped>

</style>
